<template>
  <RightSideBar
    v-if="isOpen"
    @submit="isOpen"
    @close="isOpen = false, $emit('close')"
    close-button
    button-class="bg-dynamicBackBtn text-white"
    width="520px"
  >
    <template v-slot:title>
      <div class="flex justify-start items-center">
        <span class="text-darkPurple font-bold text-lg leading-5 p-0 -ml-2">
          View Details
        </span>
      </div>
    </template>
    <template>
      <div class="w-full flex flex-col gap-5 divide-y divide-dashed divide-romanSilver">
        <div class="w-full flex flex-col justify-start items-start gap-5">
          <div class="w-full flex justify-between items-center uppercase">
            <span class="font-semibold text-sm leading-5 text-romanSilver">
              initial value:
            </span>
            <span class="font-semibold text-sm leading-5 text-jet">
              {{ convertToCurrency(history.initialValue) }}
            </span>
          </div>
          <div class="w-full flex justify-between items-center uppercase">
            <span class="font-semibold text-sm leading-5 text-romanSilver">
              Initial range minimum:
            </span>
            <span class="font-semibold text-sm leading-5 text-jet">
            {{ convertToCurrency(history.initialRangeMinimum) }}
            </span>
          </div>
          <div class="w-full flex justify-between items-center uppercase">
            <span class="font-semibold text-sm leading-5 text-romanSilver uppercase">
              initial range midpoint:
            </span>
            <span class="font-semibold text-sm leading-5 text-jet">
              {{ convertToCurrency(history.initialRangeMidPoint) }}
            </span>
          </div>
          <div class="w-full flex justify-between items-center uppercase">
            <span class="font-semibold text-sm leading-5 text-romanSilver uppercase">
              initial range maximum
            </span>
            <span class="font-semibold text-sm leading-5 text-jet">
              {{ convertToCurrency(history.initialRangeMaximum) }}
            </span>
          </div>
          <div class="w-full flex justify-between items-center">
            <span class="font-semibold text-sm leading-5 text-romanSilver uppercase">
            Revision factor
            </span>
            <div class="font-semibold text-sm leading-5 text-jet capitalize">
              <p v-if="history.reviseFactor">
                <span v-if="history.adjustBy === 'amount'">
                  {{ convertToCurrency(history.reviseFactor) }}
                </span>
                <span v-else>{{ history.reviseFactor.toFixed(2) }}%</span>
                </p>
            </div>
          </div>
        </div>
        <div class="w-full flex flex-col justify-start items-start gap-5 pt-5">
          <div class="w-full flex justify-between items-center">
            <span class="font-semibold text-sm leading-5 text-romanSilver uppercase">
              Current range minimum:
            </span>
            <span class="font-semibold text-sm leading-5 text-jet capitalize">
              {{ convertToCurrency(history.currentRangeMinimum) }}
            </span>
          </div>
          <div class="w-full flex justify-between items-center">
            <span class="font-semibold text-sm leading-5 text-romanSilver uppercase">
              Current range midpoint:
            </span>
            <span class="font-semibold text-sm leading-5 text-jet capitalize">
              {{ convertToCurrency(history.currentRangeMidPoint) }}
            </span>
          </div>
          <div class="w-full flex justify-between items-center">
            <span class="font-semibold text-sm leading-5 text-romanSilver uppercase">
              Current range maximum
            </span>
            <span class="font-semibold text-sm leading-5 text-jet capitalize">
              {{ convertToCurrency(history.currentRangeMaximum) }}
            </span>
          </div>
          <div class="w-full flex justify-between items-center">
            <span class="font-semibold text-sm leading-5 text-romanSilver uppercase">
              date revised:
            </span>
            <span class="font-semibold text-sm leading-5 text-jet capitalize">
            {{ $DATEFORMAT(new Date(history.updatedAt), "MMMM dd, yyyy") }}
            </span>
          </div>
          <div class="w-full flex justify-between items-center">
            <span class="font-semibold text-sm leading-5 text-romanSilver uppercase">
              revised by
            </span>
            <span v-if="history.revisedBy" class="font-semibold text-sm leading-5 text-jet capitalize">
              {{ history.revisedBy.fname }} {{ history.revisedBy.lname }}
            </span>
          </div>
        </div>
      </div>
    </template>
  </RightSideBar>
</template>

<script>
  import RightSideBar from "@/components/RightSideBar";

  export default {
    name: 'ViewHistoryDetails',
    components: {
      RightSideBar
    },
    data(){
      return {
        isOpen: false,
        history: {}
      }
    },
    methods: {
      toggle(history){
        this.history = history
        this.isOpen = true
      },
    },
  }
</script>

<style>
  .rightSidebar{
    width: 520px !important;
  }
</style>

